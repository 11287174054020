import { Outlet, Link } from "react-router-dom";
import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import App from "./App";
import { FontsLayout } from "./App";
import { AboutLayout } from "./App";
import "./index.css";

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
        <Route path="home" element={<App />} />
      <Route path="about" element={<AboutLayout />} />
      <Route path="research" element={<AboutLayout />} />
      <Route path="fonts" element={<FontsLayout />} />
      <Route
        path="*"
        element={
          <main style={{ padding: "1rem" }}>
            <p>There's nothing here!</p>
          </main>
        }
      />
    </Routes>
  </BrowserRouter>,
  rootElement
);