import { Link } from "react-router-dom";
import Home from "./routes/home";
import About from "./routes/about";
import Fonts from "./routes/fonts";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";

export default function App() {
  return (
    <div>
      <NavBar></NavBar>
      <Home />
      <Footer></Footer>
    </div>
  );
}

export function AboutLayout() {
  return (
    <div>
      <NavBar></NavBar>
      <About />
      <Footer></Footer>
    </div>
  );
}

export function FontsLayout() {
  return (
    <div>
      <NavBar></NavBar>
      <Fonts />
      <Footer></Footer>
    </div>
  );
}

