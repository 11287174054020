import React from 'react';

export default function Home() {
    console.log('About')
    return (
        <div>
            <div className="container mx-auto mt-8 justify-center text-center">
                <section className="mb-8">
                    <p className="italic">
                        Content pending. Please check back later.
                    </p>
                </section>
            </div>
        </div>
    )
}