import React from 'react';

export default function Font() {
    return (
        <div className="container mx-auto mt-8 justify-center text-center">
            {/* Render all the variations of the font family using Tailwind */}
            <h1 className="font-sans font-light">Light weight text</h1>
            <h1 className="font-sans font-normal">Normal weight text</h1>
            <h1 className="font-sans font-bold">Bold weight text</h1>
            <h1 className="font-sans font-extrabold">Extra bold weight text</h1>
            <h1 className="font-sans font-black">Black weight text</h1>
            <h1 className="font-sans font-thin">Thin weight text</h1>
            <h1 className="font-sans font-extralight">Extra light weight text</h1>
            <h1 className="font-sans font-semibold">Semi bold weight text</h1>
            
            {/* Making the font smaller */}
            <h1 className="font-sans text-sm">Small text</h1>
            <h1 className="font-sans text-base">Base text</h1>
            <h1 className="font-sans text-lg">Large text</h1>
            <h1 className="font-sans text-xl">Extra large text</h1>
            <h1 className="font-sans text-2xl">2 extra large text</h1>
            <h1 className="font-sans text-3xl">3 extra large text</h1>
      </div>
    )
}