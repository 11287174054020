import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { FaCog } from "react-icons/fa"; // Import the settings icon from a relevant icon library

export default function NavBar() {
  // Create a state variable to track the visibility of the dropdown menu
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Function to toggle the visibility of the dropdown menu
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Function to open the dropdown menu
  const openDropdown = () => {
    setIsDropdownOpen(true);
  };

  // Function to close the dropdown menu
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  return (
    <nav className="bg-white shadow-md px-5 py-2 flex items-center justify-between">
      <div className="flex items-center">
        <div
          className="cursor-pointer"
          onClick={toggleDropdown}
          onMouseEnter={openDropdown}
          onMouseLeave={closeDropdown}
        >
          <div classNamm="hamburger">
          <div className="w-10 h-1.5 bg-gray-800 mb-1.5 rounded-full"></div>
          <div className="w-10 h-1.5 bg-gray-800 mb-1.5 rounded-full"></div>
          <div className="w-10 h-1.5 bg-gray-800 rounded-full"></div>
          </div>
        </div>
      </div>

      <div className="flex-grow flex items-center justify-center">
        <Link to="/" className="">
          <div className="text-3xl font-light py-4">Kavi Sekhon</div>
        </Link>
      </div>

      {/* Add settings icon */}
      <div className="text-gray-800 cursor-pointer">
        <FaCog size={24} />
      </div>

      {/* Dropdown menu */}
      {isDropdownOpen && (
        <div className="absolute mt-40 bg-white shadow-lg py-2 px-4" onMouseEnter={openDropdown} onMouseLeave={closeDropdown}>
          <div className="nav-link block font-sans font-thin text-2xl mb-3 text-gray-800">
            <Link to="/home">Home</Link>
          </div>
          <div className="nav-link block font-sans font-thin text-2xl mb-3 text-gray-800">
            <Link to="/about">About</Link>
          </div>
          <div className="nav-link block font-sans font-thin text-2xl mb-3 text-gray-800">
            <Link to="/fonts">Tailwind CSS</Link>
          </div>
          <div className="nav-link block font-sans font-thin text-2xl mb-3 text-gray-800">
            <Link to="/research">Research</Link>
          </div>
        </div>
      )}
      {/* If you have other navigation links or elements, they should go here with proper spacing. */}
    </nav>
  );
}
