import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { FaEnvelope, FaGithub } from "react-icons/fa"; // Import icons from a library like react-icons

const Footer = () => (
  <footer className="p-6">
    <div className="flex items-center justify-center flex-wrap">
      <h3 className="text-xs mb-1 text-center">
        Kavi Sekhon, Independent Developer & Researcher
      </h3>
    </div>
    <div className="flex items-center justify-center flex-wrap">
      <h3 className="text-xs mb-3 text-center">
        Data / AI / ML / Infrastructure
      </h3>
    </div>
    <div className="flex items-center justify-center flex-wrap">
      <h3 className="mb-3 text-center">
        <span className="flex items-center">
          {/* Wrap icons in a flex container */}
          <a href="mailto:kavi.sekhon@kavisekhon.com">
            <FaEnvelope style={{ fontSize: "15px", marginRight: "4px" }} />
          </a>
          <a href="https://github.com/kavisek">
            <FaGithub style={{ fontSize: "15px", marginLeft: "4px" }} />
          </a>
        </span>
      </h3>
    </div>
  </footer>
);

export default Footer;
